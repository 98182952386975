.GradientText {
  background: linear-gradient(
    90deg,
    rgb(51, 153, 255) 5%,
    rgb(51, 153, 255) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  text-decoration: none;
}

.LandingBackground {
  --grid-color: hsla(210, 100%, 30%, 0.125);
  --grid-size: 39px;

  background: linear-gradient(180deg, hsla(210, 100%, 50%, 0.0175), #0f1214 85%),
    repeating-linear-gradient(
      180deg,
      transparent 0px,
      transparent calc(var(--grid-size) - 1px),
      var(--grid-color) calc(var(--grid-size) - 1px),
      var(--grid-color) var(--grid-size)
    ),
    repeating-linear-gradient(
      90deg,
      transparent 0px,
      transparent calc(var(--grid-size) - 1px),
      var(--grid-color) calc(var(--grid-size) - 1px),
      var(--grid-color) var(--grid-size)
    );
  background-position: center top;
  background-blend-mode: darken;

  background-size:
    auto 100%,
    var(--grid-size) var(--grid-size),
    var(--grid-size) var(--grid-size);

  &.LandingBackgroundCenter {
    background: radial-gradient(
        circle,
        hsla(210, 100%, 50%, 0.015),
        rgb(15, 18, 20) 65%
      ),
      repeating-linear-gradient(
        0deg,
        transparent 0px,
        transparent calc(var(--grid-size) - 1px),
        var(--grid-color) calc(var(--grid-size) - 1px),
        var(--grid-color) var(--grid-size)
      ),
      repeating-linear-gradient(
        90deg,
        transparent 0px,
        transparent calc(var(--grid-size) - 1px),
        var(--grid-color) calc(var(--grid-size) - 1px),
        var(--grid-color) var(--grid-size)
      );

    background-position: center center;
    background-size:
      auto 100%,
      var(--grid-size) var(--grid-size),
      var(--grid-size) var(--grid-size);
  }
}

.LandingGradient {
  background: linear-gradient(
    45deg,
    hsla(210, 100%, 20%, 0.05),
    rgba(15, 18, 20, 0.5) 65%
  );
}

.LandingGradientBottom {
  background: linear-gradient(
    45deg,
    hsla(210, 100%, 40%, 0.05),
    rgba(15, 18, 20, 0.5) 65%
  );
}

.HeadingScrollMargin {
  scroll-margin-top: 25px;
  scroll-snap-margin-top: 25px; /* iOS 11 and older */
}

.MarkdownBold {
  color: white;
}

.ast-highlight {
  background-color: hsla(210, 100%, 60%, 0.2);
  /* color: hsl(210, 100%, 60%) !important; */
}

.monaco-editor {
  outline: none !important;
}

.hidden {
  display: none !important;
}

.blinking {
  opacity: 1;
  animation: blinkOpacity 0.8s infinite;
}

@keyframes blinkOpacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-text {
  display: inline-block;
  background: rgb(152, 164, 179)
    linear-gradient(
      90deg,
      rgb(152, 164, 179) 0%,
      rgb(255, 255, 255) 50%,
      rgb(152, 164, 179) 100%
    );
  background-size: 50% 200%;
  background-position: -100% top;
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: loading-shimmer 3s infinite;
  animation-delay: 0.5s;
}

.loading-text:hover {
  color: rgb(255, 255, 255);
  animation: none;
  background: transparent;
}

@keyframes loading-shimmer {
  0% {
    background-position: -100% top;
  }
  100% {
    background-position: 250% top;
  }
}

.GradientShineTextPrimary {
  display: inline-block;
  background: hsl(210, 100%, 60%)
    linear-gradient(
      90deg,
      hsl(210, 100%, 60%) 0%,
      hsl(210, 100%, 80%) 50%,
      hsl(210, 100%, 60%) 100%
    );
  background-size: 50% 200%;
  background-position: -100% top;
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: loading-shine-primary 3s infinite;
  animation-delay: 1s;
}

.GradientShineTextPrimary:hover {
  color: hsl(210, 100%, 60%);
  animation: none;
  background: transparent;
}

.GradientShineTextPrimary svg {
  fill: hsl(210, 100%, 60%);
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes loading-shine-primary {
  0% {
    background-position: -100% top;
  }
  100% {
    background-position: 250% top;
  }
}

.fade-in-landing-animation {
  animation: fadeInLandingAnimation 1s ease;
}

@keyframes fadeInLandingAnimation {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-quick-animation {
  animation: fadeInQuickAnimation 0.5s ease;
}

@keyframes fadeInQuickAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing-hero-section-animation {
  animation: landingHeroSectionAnimation 1.25s ease;
}

@keyframes landingHeroSectionAnimation {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
